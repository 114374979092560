<template>
  <div :data-testid="componentID()">
    <page-not-found v-if="fourOhFour"/>
    <page-level-error v-if="pageLevelError"/>
    <div v-if="!loaded" class="loading"/>
    <article v-else-if="!fourOhFour && !pageLevelError" ref="articlePage" class="article-page">
      <!--reading indicator would go here for future reference-->
      <article-page-grid>
        <template #article-hero>
          <article-header :data="article" :title="article.title" :vertical="article.vertical" :author-data="author" :format="article.format" :is-pro="embedTypes.includes('restrict')"/>
        </template>
        <template #article-body>
          <article-page-hero :data="article" :title="article.title" :vertical="article.vertical"/>
          <article-body
            :html="html"
            :article-json="articleJson"
            :vertical="article.vertical"
            :include-promos="deviceType === 'mobile'"
            :copy-blocker="copyBlocker"
            :tracking="trackingData"
            @embedsFound="embedList"/>
          <promoted-topics v-if="article.tags" only-chips :included-topics="article.tags"/>
          <article-line-spacer/>
          <author-bio
            :author-name="author.name"
            :author-uuid="author.uuid"
            :author-image="author.imageURL"
            :author-bio="author.bio"/>
        </template>
        <template #article-side-rail>
          <article-side-rail v-show="showSideRail" content-types="articles|videos" :vertical="article.vertical" :exclude="article.uuid"/>
        </template>
        <template #article-related-content>
          <related-content-template>
            <template #colors-carousel>
              <tag-carousel
                v-if="commanderColorLabels"
                skip-article-pool
                :title="`More ${commanderColorLabels} Commanders`"
                :commander-colors="article.internalTags"
                :vertical="relatedTagsVertical"
                :exclude="article.uuid"/>
            </template>
            <template #series-carousel>
              <series-carousel v-if="article.taxonomyName" :exclude="article.uuid" :vertical="article.vertical" :series="article.taxonomyName"/>
            </template>
            <template #related-grid>
              <view-more-content :exclude="article.uuid" :vertical="article.vertical" :title="verticalTagName"/>
            </template>
            <template #more-in-tag>
              <tag-carousel
                v-if="hasTag"
                skip-article-pool
                :title="`More ${article.tags[0]} Content`"
                :tags="article.tags[0]"
                :vertical="relatedTagsVertical"
                :exclude="article.uuid"
                :min-items="3"
                :see-more="{ name: 'topic', params: { tag: article.tags[0].replace(/ /g, '-') } }"/>
            </template>
          </related-content-template>
        </template>
      </article-page-grid>
      <article-schema
        v-if="article.dateTime"
        :headline="article.title"
        :images="[article.imageURL]"
        :date-published="article.dateTime || ''"
        :date-modified="article.updatedTime || ''"
        :author-name="author.name || ''"
        :author-url="author.canonicalURL || ''"/>
    </article>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useHead } from '@vueuse/head';
import { get } from '@vueuse/core';
import { mapFields } from 'vuex-map-fields';
import { mapActions, useStore } from 'vuex';
import { VerticalHelpers as verts } from '@tcgplayer/martech-components';
import useCommanderColorLabels from '@/use/series/useCommanderColorLabels';
import useColorIdentity from '@/use/series/useColorIdentity';

import amplitudeEvent from '@tcgplayer/amplitude';
import ArticleHeader from '@/components/article-elements/ArticleHeader.vue';
import ArticleBody from '@/components/article-elements/ArticleBody.vue';
import ArticleLineSpacer from '@/components/article-elements/ArticleLineSpacer.vue';
import ArticleSideRail from '@/components/article-elements/ArticleSideRail.vue';
import ArticlePageGrid from '@/components/grid/ArticlePageGrid.vue';
import ArticlePageHero from '@/components/hero-types/ArticlePageHero.vue';
import ArticleSchema from '@/components/article-elements/ArticleSchema.vue';
import AuthorBio from '@/components/article-elements/AuthorBioSmall.vue';
import PageLevelError from '@/views/PageLevelError.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import ViewMoreContent from '@/components/article-elements/ViewMoreContent.vue';
import deviceType from '@/mixins/deviceType';
import RelatedContentTemplate from '@/components/templates/RelatedContentTemplate.vue';
import SeriesCarousel from '@/components/carousel/SeriesCarousel.vue';
import TagCarousel from '@/components/carousel/TagCarousel.vue';
import PromotedTopics from '@/components/homepage/PromotedTopics.vue';

export default {
  name: 'article-read-page',
  components: {
    ArticleHeader,
    ArticleBody,
    ArticleLineSpacer,
    ArticleSideRail,
    ArticlePageGrid,
    ArticlePageHero,
    ArticleSchema,
    AuthorBio,
    PageLevelError,
    PageNotFound,
    ViewMoreContent,
    RelatedContentTemplate,
    SeriesCarousel,
    TagCarousel,
    PromotedTopics,
  },
  mixins: [ deviceType ],
  setup() {
    const store = useStore();

    const data = ref(store.state.article);
    const article = computed(() => get(data).article);
    const author = computed(() => get(data).author);
    const internalTags = computed(() => get(article)?.internalTags);

    const { colorIdentity } = useColorIdentity(internalTags);

    const { commanderColorLabels } = useCommanderColorLabels(colorIdentity);

    const head = computed(() => ({
      title: get(article)?.title || '',
      titleTemplate: `%s | ${process.env.VUE_APP_TITLE}`,
      meta: [
        { property: 'title', content: get(article)?.title || '' },
        { name: 'description', content: get(article)?.metaDescription || get(article)?.teaser || '' },
        { property: 'og:article:author', content: get(author)?.name || '' },
        { property: 'og:article:published_time', content: get(article)?.date || '' },
        { property: 'og:description', content: get(article)?.teaser || get(article)?.metaDescription || '' },
        { property: 'og:image', content: get(article)?.imageURL || '' },
        { property: 'og:title', content: get(article)?.title || '' },
        { property: 'og:type', content: 'article' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:description', content: get(article)?.teaser || get(article)?.metaDescription || '' },
        { name: 'twitter:image', content: get(article)?.imageURL || '' },
        { name: 'twitter:text:title', content: get(article)?.title || '' },
        { name: 'twitter:title', content: get(article)?.title || '' }
      ],
    }));

    useHead(head);

    return {
      commanderColorLabels,
    };
  },
  data() {
    return {
      error: null,
      fourOhFour: false,
      fromPath: '',
      loaded: false,
      pageLevelError: false,
      showSideRail: false,
      trackingData: {},
    };
  },
  computed: {
    ...mapFields('article', [ 'article', 'author', 'embedTypes' ]),
    html() {
      return this.article?.body?.processed || this.article?.body || null;
    },
    articleJson() {
      // This is weird...
      // If you just have \" for escaping all of your quotes in the JSON it will escape all quotes as \" and
      // you can't tell the difference between \"prop\": \"value\" and \"prop\": \"some \" in my string\"
      //
      // If you have 1 \\" to start (or anywhere, start is just convenient) the field it will create \\\\\"
      // and the rest will get \"prop\": \"some \\" in my string\" with the proper double backslash.
      //
      // For now I'm requiring that and we'll just start after the initial invalid piece
      return JSON.parse(this.article?.data?.substring(5) || '{}');
    },
    verticalTagName() {
      return verts.displayName(this.getVertical(), null);
    },
    getVerticalLink() {
      return verts.verticalLink(this.getVertical(), null);
    },
    copyBlocker() {
      return Array.isArray(this.embedTypes)
        && this.embedTypes.includes('restrict')
        && !process.env.VUE_APP_IGNORE_COPY_BLOCKER;
    },
    hasTag() {
      return !!this.article?.tags?.[0];
    },
    relatedTagsVertical() {
      const vert = (this.article?.vertical || '').toLowerCase();
      return this.siteConfig('global.popularVerticals').includes(vert) ? vert : undefined;
    },
  },
  async created() {
    this.fromPath = this.$route.meta.fromPath;
    // Responses outside the 200-300 range are handled as rejected promises
    const articleId = this.$route.params.id;
    await this.loadArticle(articleId, true)
      .then(() => {
        this.loaded = true;
        this.embedList(this.embedTypes);

        const articlePage = this?.$refs?.articlePage;

        if (articlePage) {
          articlePage.style['min-height'] = 'auto';
        }
      })
      .catch((error) => {
        const res = error?.response || {};
        if (res.status === 404) {
          this.fourOhFour = true;
          this.article.title = 'Not Found';
          return;
        }

        if (res.status > 400) {
          this.pageLevelError = true;
          this.article.title = 'Uh Oh';
        }
      });
  },
  mounted() {
    window.setTimeout(() => {
      this.showSideRail = true;
    }, 500);
  },
  methods: {
    ...mapActions({
      loadArticle: 'article/loadArticle',
    }),
    embedList(embedsFound) {
      let tags = [];
      if (this.article.tags) {
        tags = tags.concat(this.article.tags);
      }
      if (this.article.internalTags) {
        tags = tags.concat(this.article.internalTags);
      }
      this.trackingData = {
        articleEmbeds: embedsFound,
        author: this.author.name,
        category: this.article.contentType,
        datePublished: this.article.date,
        facebookBoost: this?.$route?.query?.facebookBoost === 'true',
        format: this.article.format,
        fromPath: this.fromPath,
        seriesName: this.article.taxonomyName,
        source: this?.$route?.query?.source,
        tags,
        title: this.article.title,
        vertical: this.article.vertical,
      };
      amplitudeEvent('infinite', 'infiniteArticleView', this.trackingData);
    },
    getVertical() {
      let vert = '';
      if (typeof this.article.vertical === 'string') {
        vert = this.article.vertical;
      }
      return vert.toLowerCase();
    },
  },
};
</script>

<!-- eslint-disable-next-line -->
<style lang="scss">
@import '@/scss/components/_article.scss';

.loading {
  min-height: 100vh;
}

.article-page {
  .tag-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .tag-separator {
    font-family: $martech-display-inter;
    font-size: $martech-type-10;
    font-weight: $martech-weight-bold;
    letter-spacing: 1px;
    line-height: $martech-type-10;
    padding: 0.25rem;
  }
}

.author-bio-link {
  color: $martech-link;
  text-decoration: none;

  &:hover {
    color: $martech-blue-hover;
  }
}
</style>
